import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {NGX_FACEBOOK_OPTIONS} from './ngx-facebook-config.injector';

@Injectable()
export class NgxFacebookService {

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId,
    @Inject(NGX_FACEBOOK_OPTIONS) private readonly options
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.init(options);
    }
  }

  private init(options: facebook.InitParams) {
    (window as any).fbAsyncInit = () => FB.init(options);

    (function (d, s: string, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  share(options: facebook.ShareDialogParams): Observable<facebook.ShareDialogResponse> {
    return Observable.create((observer) => {
      if (!isPlatformBrowser(this.platformId)) {
        observer.error('Browser platform required');
        return;
      }
      FB.ui(options, (result: facebook.ShareDialogResponse) => {
        if (result.error_message) {
          observer.error(result);
        } else {
          observer.next(result);
          observer.complete();
        }
      });
    });
  }
}
