import {ModuleWithProviders, NgModule} from '@angular/core';
import {NgxFacebookService} from './ngx-facebook.service';
import {NGX_FACEBOOK_OPTIONS} from './ngx-facebook-config.injector';

@NgModule({})
export class NgxFacebookModule {
  static forRoot(options: facebook.InitParams): ModuleWithProviders {
    return {
      ngModule: NgxFacebookModule,
      providers: [
        NgxFacebookService,
        {provide: NGX_FACEBOOK_OPTIONS, useValue: options}
      ]
    };
  }
}
